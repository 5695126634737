import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { withPage } from "../PageContainer";
import * as Ant from "antd";
const validator = require("validator");

const UiState = {
  init: 0,
  submitting: 1,
  submitted: 2,
};

class ForgetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.init,
      values: {
        email: "",
      },
    };
  }

  render() {
    const { uiState, values } = this.state;

    return (
      <Wrapper>
        <div className="center-content">
          {(uiState === UiState.submitted && (
            <>
              <h2>成功!</h2>
              <p>請至您的註冊信箱收取重設密碼郵件: {values.email}</p>
            </>
          )) || (
            <>
              <h2>忘記密碼</h2>

              <div>
                <label htmlFor="fgpass-email-input">您的註冊Email</label>
                <Ant.Input
                  id="fgpass-email-input"
                  value={values.email}
                  onChange={e =>
                    this.setState({
                      values: { ...values, email: e.target.value },
                    })
                  }
                />
              </div>

              <BottomBar>
                <Ant.Button
                  type="primary"
                  loading={uiState === UiState.submitted}
                  onClick={this._handleSubmit}
                >
                  送出
                </Ant.Button>
              </BottomBar>
            </>
          )}
        </div>
      </Wrapper>
    );
  }

  _handleSubmit = async () => {
    const { email } = this.state.values;
    const { appActions } = this.props;

    if (!validator.isEmail(email)) {
      alert("Not a valid email");
      return;
    }

    this.setState({ uiState: UiState.submitting });

    try {
      await appActions.forgetPassword({ email });
    } catch (ex) {
      alert("API Error");
    }

    this.setState({ uiState: UiState.submitted });
  };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  .center-content {
    max-width: 600px;
    margin: 20px auto;
  }
`;

const BottomBar = styled.div`
  margin-top: 10px;
  display: flex;
`;

export default withPage(connect(null, ActionCreator)(ForgetPasswordPage));
